<template>
    <div class="wrapper wrapper_cabinet">
      <div class="links-main">
        <div class="container-link" data-cy="list-links"> 
          <div><a href="https://twitter.com/BringFinance" target="_blank" rel="nofollow"><span class="twitter"></span></a></div>
          <div><a href="https://medium.com/@BringFinance" target="_blank" rel="nofollow"><span class="medium"></span></a></div>
          <div><a href="https://www.youtube.com/channel/UCyG5ZG13kiXvvSeRxdIG_jg"  target="_blank" rel="nofollow"><span class="youtube"></span></a></div>
          <div><a href="https://www.reddit.com/r/bRingFinance/"  target="_blank"><span class="reddit" rel="nofollow"></span></a></div>
          <div><a href="https://t.me/bRing_community"  target="_blank"><span class="telegram" rel="nofollow"></span></a></div>
          <div><a href="https://t.me/bRing_channel" target="_blank"><span class="telegram speaker" rel="nofollow"> <i class="i-speaker"></i></span></a></div>
        </div>
      </div>
    <header-component @connectWallet="$emit('setWalletAndAddress')" @changeWalletRequest="$emit('changeWalletRequest')"/>
    <router-view />
    </div>
</template>

<script>

import MultiLang from '@/core/multilang'; 
import {mapState} from 'vuex';
import HeaderComponent from '../components/HeaderComponent.vue';
import ReferralController from "../controllers/ReferralController"
export default {
  components: { HeaderComponent },
  data: function() {
    return {
      lang: new MultiLang(this),
    }
  },
  computed: {
    ...mapState(['currentAddress']),
    addressShort(){

      if(this.currentAddress) {
          return this.currentAddress.slice(0, 3) + "..." + this.currentAddress.slice(this.currentAddress.length - 3, this.currentAddress.length)
      }

    }
  },
  mounted() {
    this.lang.init();
    ReferralController.setReferrerAddressIfExists(this) 
  },
  methods: {
    //TODO change when deployed to binance
    // connectWallet() {
    //   try {
    //     let currentAccount = localStorage.getItem("address")
    //     if(window.ethereum){
    //             window.ethereum
    //               .request({ method: 'eth_requestAccounts' })
    //               .then(handleAccountsChanged)
    //               .catch((err) => {
    //                 if(err.code == "-32002"){
    //                   alert('Proceed to your metamask extension')
    //                 };
    //               });

    //             // Note that this event is emitted on page load.
    //             // If the array of accounts is non-empty, you're already
    //             // connected.
    //             window.ethereum.on('accountsChanged', handleAccountsChanged);

    //           // For now, 'eth_accounts' will continue to always return an array
    //           function handleAccountsChanged(accounts) {
    //             if (accounts.length === 0) {

    //               localStorage.removeItem('address')
    //               _this.$store.commit("setCurrentAddress", "")

    //               // MetaMask is locked or the user has not connected any accounts
    //               // alert('Please connect to MetaMask.');
    //             } else if (accounts[0] !== currentAccount) {
    //               currentAccount = accounts[0];
    //               localStorage.setItem("address", currentAccount);
    //                     // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
    //               location.reload();
    //             }
    //           }
    //   }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
  },

}
</script>
</style>


